<template>
  <!-- App.vue -->
  <v-app>
    <v-main class="orange darken-3 d-flex align-items-center">
      <div data-aos="fade" data-aos-duration="1000" style="height: 100vh;" v-if="part == 0">
        <div class="d-flex flex-column justify-content-around align-items-center" style="height: 100%;">
          <div class="container text-center"></div>
          <div class="container text-center p-0 d-none d-md-block w-100">
            <img :src="require('../images/umoja.jpeg')" class="w-25" data-aos="flip-up" data-aos-duration="1000" />
          </div>
          <div class="container text-center p-0 d-block d-md-none w-100">
            <img :src="require('@/images/umoja.jpeg')" class="w-50" data-aos="flip-up" data-aos-duration="1000" />
          </div>
          <div class="container text-center p-0">
            <h3 class="display-1 white--text">UMOJA Scholarship</h3>
            <!-- <h3 class="overline white--text">version 1.0</h3> -->
          </div>
        </div>
      </div>

      <div data-aos="fade" data-aos-duration="1000" class="white" style="max-width: 500px; margin: auto;" v-else>
        <div class="d-flex flex-column justify-content-around align-items-center"
          style="background: rgba(0,0,0,0);height: 100%;">
          <div class="container text-center">
            <img :src="require('../images/umoja.png')" class="w-25" />

            <h1 data-aos="slide-left" data-aos-duration="1000" class="titre">
              Login<br />
            </h1>

            <!-- <div class="text-center">
              <v-btn dense text href="#/signupbefore" class="overline">Create a new Account</v-btn>
            </div> -->
          </div>

          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-sm-10 col-md-9 col-lg-12">
                <div class="row d-flex justify-content-center align-items-center">
                  <div class="col-10 col-sm-8 col-md-8 col-lg-9" style="text-align: center;padding: 7px;">
                    <!-- <form> -->
                    <input class="form-control" type="text" name="Email" placeholder="Email" v-model="email"
                      style="border-radius: 10px;background: rgba(0,0,0,0); color:black;" @keypress.enter="login()" />
                    <!-- </form> -->
                  </div>
                  <div class="col-10 col-sm-8 col-md-8 col-lg-9" style="text-align: center;padding: 7px;">
                    <!-- <form> -->
                    <input class="form-control" type="password" v-model="password"
                      style="border-radius: 10px;background: rgba(0,0,0,0); color:black;" placeholder="Mot de passe"
                      name="Password" @keypress.enter="login()" />
                    <!-- </form> -->
                  </div>
                  <div class="my-5 text-center">
                    <v-btn color="#2962ff" class="elevation-0 white--text" @click="login()">Sign in</v-btn>
                  </div>
                  <!-- <div
                    class="col-12 col-md-12 mt-2"
                    style="text-align: center;"
                  >
                    <a
                      class="grey--text"
                      href="#/signup"
                      style="font-size: 12px;"
                      >J'ai oublié mon mot de passe<br
                    /></a>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-main>
    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
        <span class="ml-2">Connexion</span>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
  import firebase from "firebase";
  // import ServiceApi from "../ServicesApi.js";

  import db from "../db.js";

  export default {
    data() {
      return {
        email: "",
        password: "",
        erreur: "",
        dialogErreur: false,
        dialogWait: false,
        query: "",
        part: 0
      };
    },
    mounted() {
      setTimeout(() => {
        this.part = 1;
      }, 5000);
    },
    methods: {
      login() {
        var that = this;
        that.dialogWait = true;
        document.cookie = "mutuYango=" + ";";
        document.cookie = "codeYango=" + ";";
        that.$store.state.familyNotification = [];
        firebase
          .auth()
          .signInWithEmailAndPassword(that.email, that.password)
          .then(
            elt => {
              console.log(elt.user.email);
              
              // console.log(elt.user.password);

              // var d = new Date();
              // d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
              // var expires = "expires=" + d.toUTCString();
              // document.cookie = "mutuYango=" + that.email + ";" + expires + ";";
              // document.cookie =
              //   "codeYango=" + that.password + ";" + expires + ";";
              // that.$store.state.part = 0;'
              
              var d = new Date();
              d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
              // var expires = "expires=" + d.toUTCString();
              document.cookie = "mutuYango=" + ";";
              document.cookie =
                "codeYango="+";";
              that.$store.state.part = 0;
              
              setTimeout(() => {
                that.getInfo();
              }, 2000);
            },
            error => {
              // that.erreur = error.message;
              that.dialogWait = false;
              alert(error.message);
            }
          );
      },
      getInfo() {
        var that = this;
        
        db.collection("team")
          .where("email", "==", firebase.auth().currentUser.email)
          .onSnapshot(snapshot => {

            that.$store.state.ngayi = [];

            snapshot.forEach(doc => {

              that.$store.state.ngayi = {
                id: doc.id,
                fullname: doc.data().fullname,
                email: doc.data().email,
                image: doc.data().image
              };
            });

            // var user = that.$store.state.ngayi;
            if(firebase.auth().currentUser.email) this.$router.push("/menu");


            // switch (user.type) {
            //   case "e":
            //     that.$router.push("/profile");
            //     break;

            //   // case "o":
            //   //   break;
                
            //     default:
            //       that.$router.push("/menu");
            //     break;
            // }


          });

      }
    }
  };
</script>